/**
 * Load SalesForce chat script
 * @param {Object} user - HelloAsso user
 * @param {String} organizationId - Organization id
 * @param {Boolean} receivedAPayment - If the org has received at least one payment
 */
export default function (user, organizationId, receivedAPayment) {
  const salesForceVariables = {
    name: receivedAPayment ? 'Support' : 'Team_Onboarding',
    baseUrl: process.env.NUXT_ENV_SF_BASE_URL,
    baseLiveAgentURL: process.env.NUXT_ENV_SF_BASE_LIVE_AGENT_URL,
    baseLiveAgentContentURL: process.env.NUXT_ENV_SF_BASE_LIVE_AGENT_CONTENT_URL,
    instanceId: process.env.NUXT_ENV_SF_INSTANCE_ID,
    liveAgentUrl: receivedAPayment
      ? process.env.NUXT_ENV_SF_SUPPORT_LIVE_AGENT_URL
      : process.env.NUXT_ENV_SF_ONBOARDING_LIVE_AGENT_URL,
    buttonId: receivedAPayment
      ? process.env.NUXT_ENV_SF_SUPPORT_BUTTON_ID
      : process.env.NUXT_ENV_SF_ONBOARDING_BUTTON_ID
  }

  const script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  // TODO: pass `organizationId` to request.
  script.innerHTML = `
    var initESW = function(gslbBaseURL) {
      embedded_svc.settings.displayHelpButton = true;
      embedded_svc.settings.chatbotAvatarImgURL = "https://helloasso--c.visualforce.com/resource/1665148438000/avatar_bot?";
      embedded_svc.settings.language = 'fr-FR';
      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      embedded_svc.settings.defaultMinimizedText = "Besoin d'aide ?";
      embedded_svc.settings.disabledMinimizedText = 'Agent hors ligne';
      embedded_svc.settings.loadingText = 'Chargement...';
      embedded_svc.settings.offlineSupportMinimizedText = 'Nous contacter';
      embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: "${user.firstName}",
        LastName: "${user.lastName}",
        Email: "${user.email}"
      };

      embedded_svc.settings.extraPrechatInfo = [
        {
          "entityName": "Account",
          "linkToEntityName": "Case",
          "linkToEntityField": "AccountId",
          "entityFieldMaps": [{
            "isExactMatch": true,
            "fieldName": "AssociationId__c",
            "doCreate": false,
            "doFind": true,
            "label": "AssociationId"
          }]
        },
        {
          "entityName": "Account",
          "linkToEntityName": "Contact",
          "linkToEntityField": "ContactId",
          "entityFieldMaps": [{
            "isExactMatch": true,
            "fieldName": "ContactId__c",
            "doCreate": false,
            "doFind": true,
            "label": "UserId"
          }]
        }
      ];

      embedded_svc.settings.extraPrechatFormDetails = [
        {
          "label": "AssociationId",
          "value": "${organizationId}",
          "displayToAgent": true
        },
        {
          "label": "UserId",
          "value": "${user.userId}",
          "displayToAgent": true
        }
      ];

      embedded_svc.init(
        '${salesForceVariables.baseUrl}',
        '${salesForceVariables.liveAgentUrl}',
        gslbBaseURL,
        '${salesForceVariables.instanceId}',
        '${salesForceVariables.name}',
        {
          baseLiveAgentContentURL: '${salesForceVariables.baseLiveAgentContentURL}',
          deploymentId: '5723X000000kJbE',
          buttonId: '${salesForceVariables.buttonId}',
          baseLiveAgentURL: '${salesForceVariables.baseLiveAgentURL}',
          eswLiveAgentDevName: '${salesForceVariables.name}',
          isOfflineSupportEnabled: false
        }
      );
    };

    if (!window.embedded_svc) {
      var s = document.createElement('script');
      s.setAttribute('src', '${salesForceVariables.baseUrl}/embeddedservice/5.0/esw.min.js');
      s.onload = function() {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
  `
  document.body.appendChild(script)
}
