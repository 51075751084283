import { map, find, filter, findIndex } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, FormType, StoreRouteParams } from '../helpers/tsenums'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface Source {
  form: string | null
  organization: string | null
}

interface CustomField {
  isPayerField: boolean
  linkedToAllTiers: boolean
  linkedToAllAdditionalOptions: boolean
  linkedTiers: any[]
  linkedAdditionalOptions: any[]
  id: number
  values: any[]
  isRequired: boolean
  type: string
  label: string
}

class State {
  items: CustomField[] = []
  status: Status = Status.SUCCESS
  fetchedFrom: Source = {
    form: null,
    organization: null
  }
}

export const state = () => new State()
export const getters = {
  hasLoadedCustomFields: (state) => (formSlug: string, orgSlug: string) => {
    return state.fetchedFrom.form === formSlug && state.fetchedFrom.organization === orgSlug
  }
}

export const mutations = <MutationTree<State>>{
  SET_CUSTOM_FIELDS(
    state,
    {
      customFields,
      orgSlug,
      formSlug
    }: { customFields: CustomField[]; orgSlug: string; formSlug: string }
  ) {
    state.items = customFields
    state.fetchedFrom.form = formSlug
    state.fetchedFrom.organization = orgSlug
  },
  SET_CUSTOM_FIELDS_STATUS(state, { status }: { status: Status }) {
    state.status = status
  },
  ADD_CUSTOM_FIELD(state, { customField }: { customField: CustomField }) {
    state.items = [...state.items, customField]
  },
  SET_CUSTOM_FIELD(state, { customField }: { customField: CustomField }) {
    state.items.splice(
      findIndex(state.items, (item) => customField.id === item.id),
      1,
      customField
    )
  },
  DELETE_CUSTOM_FIELD(state, { id }: { id: number }) {
    state.items = filter(state.items, (customField) => customField.id !== id)
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchCustomFields(
    { commit, getters },
    { routeParams, forceReload = false }: { routeParams: StoreRouteParams; forceReload: boolean }
  ): Promise<void> {
    const { orgSlug, formSlug, type } = routeParams
    if (!forceReload && getters.hasLoadedCustomFields()) return Promise.resolve()

    try {
      const customFields = await this.$api.$get(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/custom-fields`
      )
      commit('SET_CUSTOM_FIELDS', { customFields, formSlug, orgSlug })
      commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.SUCCESS })
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.ERROR })
      throw error
    }
  },

  createCustomField(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      customField
    }: { orgSlug: string; formSlug: string; type: FormType; customField: CustomField }
  ) {
    commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.LOADING })

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/custom-fields`, customField)
      .then((data) => {
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.SUCCESS })
        commit('ADD_CUSTOM_FIELD', { customField: data })
      })
      .catch((error) => {
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  updateCustomField(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      customField
    }: { orgSlug: string; formSlug: string; type: FormType; customField: CustomField }
  ) {
    commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.LOADING })

    return this.$api
      .$put(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/custom-fields/${customField.id}`,
        customField
      )
      .then((data) => {
        commit('SET_CUSTOM_FIELD', { customField: data })
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  deleteCustomField(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.LOADING })

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/custom-fields/${id}/disable`)
      .then(() => {
        commit('DELETE_CUSTOM_FIELD', { id })
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  orderCustomFields(
    { commit, state },
    {
      orgSlug,
      formSlug,
      type,
      customFieldsIds
    }: { orgSlug: string; formSlug: string; type: FormType; customFieldsIds: number[] }
  ) {
    commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.LOADING })

    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/custom-fields/reorder`, {
        customFieldIdList: customFieldsIds
      })
      .then(() => {
        const newCustomFields = map(customFieldsIds, (id) => find(state.items, { id }))
        commit('SET_CUSTOM_FIELDS', { customFields: newCustomFields })
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_CUSTOM_FIELDS_STATUS', { status: Status.ERROR })
        throw error
      })
  }
}
