import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { get, clone } from 'lodash-es'
import { FormType } from '../helpers/tsenums'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

declare global {
  interface Window {
    appCues: any
    dataLayer: any
    wootricSettings: any
  }
}

interface Cashouts {
  [type: string]: Cashout
}

interface Cashout {
  availableAmount: number
  debitAmount: number
  protectedAmount: number
  isCashOutProhibited: boolean
  suggestedTips: any[]
}

interface Forms {
  [type: string]: Form
}

interface nbForms {
  [type: string]: Number
}

interface Form {
  firstPaymentDate?: Date
  lastPaymentDate?: Date
  lastOrderDate?: Date
  participantCount: number
  amountCollected: number
  donationCount: number
  financialGoal?: number
  maxPrice?: number
  minPrice?: number
  tags?: string[]
  activityTypeId: number
  currency: Currency
  description?: string
  endDate?: Date
  meta: Meta
  state: FormState
  title: string
  widgetButtonUrl?: string
  widgetFullUrl?: string
  widgetVignetteHorizontalUrl?: string
  widgetVignetteVerticalUrl?: string
  formSlug: string
  formType: string
  url: string
  organizationSlug: string
  activityType?: string
  saleEndDate?: Date
  validityType?: string
  startDate?: Date
  banner?: Banner
  privateTitle?: string
  contact?: Contact
  saleStartDate?: Date
  remainingEntries?: number
}

interface EnrichedOrganizations {
  [type: string]: Organization
}

interface Organization {
  firstFormCreationDate: Date
  firstPaymentDate: Date
  id: number
  lastFormCreationDate: Date
  lastFormCreationType: string
  lastOrderDate: Date
  lastPaymentDate: Date
  tags: string[]
  authenticationState: string
  displayCoordinates: boolean
  galleryImages: GalleryImage[]
  isCmmabn: boolean
  longDescription: string
  twoFactorAuthenticationEnabled: boolean
  videos: Video[]
  webSite: string
  signUpDate: Date
  tosAccepted: boolean
  isAuthenticated: boolean
  banner: string
  contact: Contact
  fiscalReceiptEligibility: boolean
  fiscalReceiptIssuanceEnabled: boolean
  type: string
  category: string
  address: string
  geolocation: Geolocation
  logo: string
  name: string
  role: string
  city: string
  zipCode: string
  description: string
  url: string
  organizationSlug: string
}

interface GalleryImage {
  caption: string
  id: number
  url: string
}

interface Geolocation {
  latitude: number
  longitude: number
}

interface Video {
  id: number
  url: string
}

interface Banner {
  fileName: string
  publicUrl: string
}

interface Contact {
  email: string
  phoneNumber: string
}

enum Currency {
  Eur = 'EUR'
}

enum FormState {
  Private = 'Private',
  Public = 'Public'
}

interface OrganizationPublicForms {
  [type: string]: PublicForms
}

interface PublicForms {
  [type: string]: PublicForm
}

interface PublicForm {
  organizationLogo: string
  organizationName: string
  tiers: Tier[]
  activityTypeId: number
  currency: string
  description: string
  endDate: Date
  meta: Meta
  state: string
  title: string
  widgetButtonUrl: string
  widgetFullUrl: string
  widgetVignetteHorizontalUrl: string
  widgetVignetteVerticalUrl: string
  formSlug: string
  formType: string
  url: string
  organizationSlug: string
}

interface Meta {
  createdAt: Date
  updatedAt: Date
}

interface Tier {
  id: number
  label?: string
  description?: string
  tierType: string
  price?: number
  vatRate: number
  paymentFrequency: string
  isEligibleTaxReceipt: boolean
  picture?: Picture
  minAmount?: number
}

interface Picture {
  fileName: string
  publicUrl: string
}

interface FormTypes {
  [organisationSlug: string]: string[]
}

class State {
  cashouts: Cashouts = {}
  forms: Forms = {}
  publicForms: OrganizationPublicForms = {}
  enriched: EnrichedOrganizations = {}
  formTypes: FormTypes = {}
  nbForms: nbForms = {}
}

export const state = () => new State()

export const getters = {
  hasLoadedFormTypes: state => orgSlug => !!state.formTypes[orgSlug],
  hasLoadedOrganizationEnriched: state => orgSlug => !!state.enriched[orgSlug],
  hasLoadedOrganizationCashOut: state => orgSlug => state.cashouts[orgSlug] !== undefined,
  recentForms:
    (state, getters, rootState) =>
    (orgSlug: string): Form[] => {
      const slugDeletedForms =
        rootState.forms.deletedForms
          ?.filter(item => item.organizationSlug === orgSlug)
          .map(item => item.formSlug) || []
      const slugArchivedForms =
        rootState.forms.archivedForms
          ?.filter(item => item.organizationSlug === orgSlug)
          .map(item => item.formSlug) || []
      const recentForms =
        state.forms[orgSlug]?.filter(
          form =>
            !slugDeletedForms.includes(form.formSlug) && !slugArchivedForms.includes(form.formSlug)
        ) || []
      return recentForms
    }
}

export const mutations = <MutationTree<State>>{
  SET_ORGANIZATION_CASH_OUT(
    state,
    { organizationSlug, cashout }: { organizationSlug: string; cashout: Cashout }
  ) {
    state.cashouts = {
      ...state.cashouts,
      [organizationSlug]: cashout
    }
  },

  SET_ORGANIZATION_FORMS(state, { orgSlug, forms }: { orgSlug: string; forms: Form }) {
    state.forms = {
      ...state.forms,
      [orgSlug]: forms
    }
  },

  SET_ORGANIZATION_PUBLIC_FORM(
    state,
    {
      orgSlug,
      formSlug,
      form,
      formType
    }: { orgSlug: string; formSlug: string; form: PublicForm; formType: string }
  ) {
    state.publicForms = {
      ...state.publicForms,
      [orgSlug]: {
        ...state.publicForms[orgSlug],
        [formType + formSlug]: form
      }
    }
  },

  SET_ORGANIZATION_FORM_STATE(
    state,
    {
      orgSlug,
      formSlug,
      formState,
      formType
    }: { orgSlug: string; formSlug: string; formState: FormState; formType: string }
  ) {
    state.publicForms = {
      ...state.publicForms,
      [orgSlug]: {
        ...state.publicForms[orgSlug],
        [formType + formSlug]: {
          ...state.publicForms[orgSlug][formType + formSlug],
          state: formState
        }
      }
    }
  },
  SET_ORGANIZATION_ENRICHED_DATA(
    state,
    { organizationSlug, data }: { organizationSlug: string; data: Organization }
  ) {
    state.enriched = {
      ...state.enriched,
      [organizationSlug]: data
    }
  },
  SET_ORGANIZATION_FORM_TYPES(
    state,
    { formTypes, organizationSlug }: { organizationSlug: string; formTypes: string[] }
  ) {
    state.formTypes = {
      ...state.formTypes,
      [organizationSlug]: formTypes
    }
  },
  ADD_ORGANIZATION_FORM_TYPE(state, { formType, orgSlug }: { orgSlug: string; formType: string }) {
    const formTypes = state.formTypes[orgSlug]
    if (typeof formTypes !== 'undefined') {
      if (!formTypes.includes(formType)) {
        formTypes.push(formType)
      }
      state.formTypes = {
        ...state.formTypes,
        [orgSlug]: formTypes
      }
    }
  },
  SET_ACTIVE_FORMS_NUMBER(state, { nb, orgSlug }: { orgSlug: string; nb: number }) {
    state.nbForms = {
      ...state.nbForms,
      [orgSlug]: nb
    }
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchOrganizationCashOut(
    { commit, getters },
    { orgSlug, forceReload = false }: { orgSlug: string; forceReload: boolean }
  ) {
    if (!forceReload && getters.hasLoadedOrganizationCashOut(orgSlug)) {
      return Promise.resolve()
    }

    try {
      const cashout = await this.$api.$get(`/organizations/${orgSlug}/cash-out/amounts`)
      commit('SET_ORGANIZATION_CASH_OUT', { organizationSlug: orgSlug, cashout })
    } catch (error) {
      commit('SET_ORGANIZATION_CASH_OUT', { organizationSlug: orgSlug, cashout: null })
      throw error
    }
  },

  fetchRecentForms(
    { state, commit },
    {
      orgSlug,
      pageSize = 30,
      forceReload = false
    }: { orgSlug: string; pageSize: number; forceReload: boolean }
  ) {
    if (!forceReload && state.forms[orgSlug]) {
      return Promise.resolve(state.forms[orgSlug])
    }
    return this.$api
      .$get(`/organizations/${orgSlug}/recent-forms?pageSize=${pageSize}`)
      .then(async formResult => {
        const forms = get(formResult, 'data', [])
        commit('SET_ORGANIZATION_FORMS', { orgSlug, forms })
        commit('SET_ACTIVE_FORMS_NUMBER', { orgSlug, nb: formResult.countActiveForms })
        return forms
      })
      .catch(error => {
        throw error
      })
  },

  fetchForm(
    { commit },
    { orgSlug, formType, formSlug }: { orgSlug: string; formSlug: string; formType: FormType }
  ) {
    return this.$api
      .$get(`/organizations/${orgSlug}/forms/${formType}/${formSlug}/public`)
      .then(form => {
        commit('SET_ORGANIZATION_PUBLIC_FORM', { orgSlug, formSlug, form, formType })
        return form
      })
      .catch(error => {
        throw error
      })
  },

  setFormState(
    { commit, state },
    {
      orgSlug,
      formType,
      formSlug,
      formState
    }: { orgSlug: string; formSlug: string; formType: FormType; formState: FormState }
  ) {
    commit('SET_ORGANIZATION_FORM_STATE', { orgSlug, formSlug, formState, formType })

    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${formType}/${formSlug}/state`, { state: formState })
      .catch(error => {
        commit('SET_ORGANIZATION_FORM_STATE', {
          orgSlug,
          formSlug,
          formState: state.publicForms[orgSlug][formType + formSlug].state,
          formType
        })
        throw error
      })
  },

  acceptTos(_, orgSlug: string) {
    return this.$api.$post(`/organizations/${orgSlug}/tos`).catch(error => {
      throw error
    })
  },

  async fetchOrganizationEnriched(
    { commit, getters },
    { orgSlug, forceReload = false }: { orgSlug: string; forceReload: boolean }
  ): Promise<void> {
    if (!forceReload && getters.hasLoadedOrganizationEnriched(orgSlug)) {
      return Promise.resolve()
    }

    try {
      const { data } = await this.$api.get(`/organizations/${orgSlug}/enriched`)
      commit('SET_ORGANIZATION_ENRICHED_DATA', { organizationSlug: orgSlug, data })
    } catch (error) {
      throw error
    }
  },

  async fetchFormTypes(
    { state, commit, getters },
    { orgSlug, forceReload = false }: { orgSlug: string; forceReload: boolean }
  ): Promise<FormTypes> {
    if (!forceReload && getters.hasLoadedFormTypes(orgSlug)) return state.formTypes

    try {
      const formTypes = await this.$api.$get(`/organizations/${orgSlug}/formTypes`)
      commit('SET_ORGANIZATION_FORM_TYPES', { formTypes, organizationSlug: orgSlug })
      return formTypes
    } catch (error) {
      throw error
    }
  },

  async activateTwoFa({ state, commit }, orgSlug: string) {
    try {
      await this.$api.$put(`/organizations/${orgSlug}/2fa`, {
        twoFactorAuthenticationEnabled: true
      })
      const data = clone(state.enriched[orgSlug])
      data.twoFactorAuthenticationEnabled = true

      commit('SET_ORGANIZATION_ENRICHED_DATA', { data, organizationSlug: orgSlug })
    } catch (error) {
      throw error
    }
  },

  async desactivateTwoFa({ state, commit }, orgSlug: string) {
    try {
      await this.$api.$put(`/organizations/${orgSlug}/2fa`, {
        twoFactorAuthenticationEnabled: false
      })
      const data = clone(state.enriched[orgSlug])
      data.twoFactorAuthenticationEnabled = false

      commit('SET_ORGANIZATION_ENRICHED_DATA', { data, organizationSlug: orgSlug })
    } catch (error) {
      throw error
    }
  }
}
