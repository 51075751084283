import { get } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, WalletState } from '../helpers/tsenums'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

type Provider = 'StripeV2' | 'HaPay' | 'LemonWay'

interface Compliance {
  bankInformation: BankInformation
  documentsKyc: DocumentsKyc[]
  organizationPurpose: string
  paymentProvider: PaymentProvider
  representative: Representative
  rna: string
  verificationMessage: VerificationMessage
  verificationState: string
  wallet: Wallet
}

interface BankInformation {
  bic: string
  iban: string
}

interface DocumentsKyc {
  id: number
  type: string
  fileName: string
  url: string
}

interface File {
  lastModified: number
  lastModifiedDate: Date
  name: string
  size: number
  type: string
  webkitRelativePath: string
}

interface PaymentProvider {
  type: string
}

interface Representative {
  nationality: string
  birthDate: Date
  email: string
  firstName: string
  lastName: string
  id: number
  complianceState: string
}

interface StripeErrors {
  [key: string]: any
}

interface VerificationMessage {
  [key: string]: any
}

interface Wallet {
  allowCollect: boolean
  allowPayout: boolean
  hasBeenAuthenticatedOnce: boolean
  state: WalletState
}

interface CountriesAndNationalities {
  iso3166CountryNationality: [
    {
      value: string
      text: string
    }
  ]
  iso3166Country: [
    {
      value: string
      text: string
    }
  ]
}

class State {
  compliance: Compliance = {
    bankInformation: {
      iban: '',
      bic: ''
    },
    documentsKyc: [],
    organizationPurpose: '',
    paymentProvider: {
      type: ''
    },
    representative: {
      birthDate: new Date(),
      email: '',
      firstName: '',
      id: 0,
      lastName: '',
      nationality: '',
      complianceState: ''
    },
    rna: '',
    verificationMessage: {},
    verificationState: '',
    wallet: {
      allowCollect: false,
      allowPayout: false,
      hasBeenAuthenticatedOnce: false,
      state: WalletState.LIMITED
    }
  }
  complianceRetrievedOrganization: string = ''
  status: Status = Status.SUCCESS
  provider: Provider | null = null
  stripeErrors: StripeErrors = {}
}

export const state = () => new State()

export const getters = {
  dataLayerProviderName: (state: State) => {
    return state.provider === 'StripeV2' ? 'Stripe' : state.provider
  },
  isStripe: (state: State) => {
    return state.provider === 'StripeV2'
  }
}

export const mutations = <MutationTree<State>>{
  SET_COMPLIANCE(state, { compliance, orgSlug }: { compliance: Compliance; orgSlug: string }) {
    state.compliance = compliance
    state.complianceRetrievedOrganization = orgSlug
  },
  SET_COMPLIANCE_INFOS(state, { compliance }: { compliance: Compliance }) {
    // Only updates infos and not documents
    state.compliance = {
      ...state.compliance,
      ...compliance
    }
  },
  SET_COMPLIANCE_STATUS(state, status: Status) {
    state.status = status
  },
  SET_BANK_INFOS(state, bankInfos: BankInformation) {
    state.compliance.bankInformation = bankInfos
  },
  SET_IBAN(state, iban) {
    state.compliance.bankInformation.iban = iban
  },
  SET_BIC(state, bic) {
    state.compliance.bankInformation.bic = bic
  },
  SET_PROVIDER(state, provider: Provider) {
    state.provider = provider
  },
  SET_STRIPE_ERRORS(state, { orgSlug, errors }: { orgSlug: string; errors: StripeErrors }) {
    state.stripeErrors = {
      ...state.stripeErrors,
      [orgSlug]: errors
    }
  },
  SET_REPRESENTATIVE(state, { representative }: { representative: Representative }) {
    state.compliance.representative = representative
  },
  SET_FIRST_NAME(state, firstName) {
    state.compliance.representative.firstName = firstName
  },
  SET_LAST_NAME(state, lastName) {
    state.compliance.representative.lastName = lastName
  },
  SET_EMAIL(state, email) {
    state.compliance.representative.email = email
  },
  SET_BIRTH_DATE(state, birthDate) {
    state.compliance.representative.birthDate = birthDate
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchCompliance({ commit, getters, dispatch }, { orgSlug }: { orgSlug: string }) {
    await dispatch('fetchProvider', { orgSlug })
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    return this.$api
      .$get(`/organizations/${orgSlug}/compliance/${getters.isStripe ? 'v2' : 'full'}`)
      .then(compliance => {
        commit('SET_COMPLIANCE', { compliance, orgSlug })
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
        return compliance
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Create new compliance information for an organization
   * @param {string} orgSlug
   * @param {object} compliance
   */
  postCompliance(
    { commit, getters },
    { orgSlug, compliance }: { orgSlug: string; compliance: Compliance }
  ) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const url = getters.isStripe
      ? `/organizations/${orgSlug}/compliance/v2`
      : `/organizations/${orgSlug}/compliance`

    return this.$api
      .$post(url, compliance)
      .then(() => {
        commit('SET_COMPLIANCE_INFOS', { compliance })
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Upload a compliance document
   * @param {string} orgSlug
   * @param {string} docType
   * @param {File} file
   */
  postComplianceDocument(
    { commit, getters },
    { orgSlug, docType, file }: { orgSlug: string; docType: string; file: File | any }
  ) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file.name)

    const url = getters.isStripe
      ? `/organizations/${orgSlug}/compliance/v2/documents?type=${docType}`
      : `/organizations/${orgSlug}/compliance/documents?type=${docType}`

    return this.$api
      .$post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
        return `success uploading ${docType}`
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Save organization's bank information
   * @param {string} orgSlug
   * @param {object} bankInfos
   */
  postBankInfos(
    { commit, getters },
    { orgSlug, bankInfos }: { orgSlug: string; bankInfos: BankInformation }
  ) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const url = getters.isStripe
      ? `/organizations/${orgSlug}/compliance/v2/bank-information`
      : `/organizations/${orgSlug}/compliance/bank-information`

    return this.$api
      .$post(url, bankInfos)
      .then(() => {
        commit('SET_BANK_INFOS', { bankInfos })
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Send organization's compliance information to provider
   * Body is not required as the server uses the data sent with `postCompliance()`
   * @param {string} orgSlug
   */
  postComplianceToProvider({ commit, getters }, { orgSlug }: { orgSlug: string }) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const url = getters.isStripe
      ? `/organizations/${orgSlug}/compliance/v2/send-to-provider`
      : `/organizations/${orgSlug}/compliance/send-to-provider`

    return this.$api
      .$post(url)
      .then(() => {
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        if (getters.isStripe && get(error, 'response.data.errors')) {
          commit('SET_STRIPE_ERRORS', { errors: get(error, 'response.data.errors'), orgSlug })
        }

        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Send organization's compliance information to provider
   * Body is not required as the server uses the data sent with `postCompliance()`
   * Used only for stripe when compliance state is not Init
   * @param {string} orgSlug
   */
  putComplianceToProvider({ commit, state }, { orgSlug }: { orgSlug: string }) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const url = `/organizations/${orgSlug}/compliance/v2/send-to-provider`

    return this.$api
      .$put(url)
      .then(() => {
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        if (get(error, 'response.data.errors')) {
          commit('SET_STRIPE_ERRORS', { errors: get(error, 'response.data.errors'), orgSlug })
        }

        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Send organization's bank infos to provider
   * Body is not required as the server uses the data sent with `postBankInfos()`
   * @param {string} orgSlug
   */
  postBankInfosToProvider({ commit }, { orgSlug }: { orgSlug: string }) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    return this.$api
      .$post(`/organizations/${orgSlug}/compliance/bank-information/send-to-provider`)
      .then(() => {
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Create new representative (only on Stripe case)
   * @param {string} orgSlug
   * @param {object} representative
   */
  postRepresentative(
    { commit, state },
    { orgSlug, representative }: { orgSlug: string; representative: Representative }
  ) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const url = `/organizations/${orgSlug}/compliance/v2/representative`

    return this.$api
      .$post(url, representative)
      .then(() => {
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Update representative (only on Stripe case)
   * @param {string} orgSlug
   * @param {object} representative
   */
  putRepresentative(
    { commit, state },
    { orgSlug, representative }: { orgSlug: string; representative: Representative }
  ) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const representativePut = { representative, representativeId: representative.id }
    const url = `/organizations/${orgSlug}/compliance/v2/representative`

    return this.$api
      .$put(url, representativePut)
      .then(() => {
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Create new representative (only on Stripe case)
   * @param {string} orgSlug
   */
  fetchRepresentative({ commit, state }, { orgSlug }: { orgSlug: string }) {
    commit('SET_COMPLIANCE_STATUS', Status.LOADING)

    const url = `/organizations/${orgSlug}/compliance/v2/representative`

    return this.$api
      .$get(url)
      .then(representative => {
        commit('SET_REPRESENTATIVE', { representative })
        commit('SET_COMPLIANCE_STATUS', Status.SUCCESS)
        return representative
      })
      .catch(error => {
        commit('SET_COMPLIANCE_STATUS', Status.ERROR)
        throw error
      })
  },
  fetchProvider({ commit, state }, { orgSlug }: { orgSlug: string }) {
    return this.$api
      .$get(`/organizations/${orgSlug}/provider`)
      .then(provider => {
        commit('SET_PROVIDER', provider)
        return provider
      })
      .catch(error => {
        throw error
      })
  }
}
