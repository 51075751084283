
import { get, values } from 'lodash-es'
import { HaDropdown, HaMenuList, HaMenuListItem } from '@ha/components'
import withImageProxy from '@/mixins/withImageProxy'
import useTracking from '@/composables/useTracking'

export default {
  name: 'AppHeader',
  components: {
    HaDropdown,
    HaMenuList,
    HaMenuListItem
  },
  mixins: [withImageProxy],
  props: {
    opened: Boolean
  },
  setup() {
    const { tracking } = useTracking()
    return { tracking }
  },
  data() {
    return {
      disconnecting: false,
      open: false
    }
  },
  computed: {
    organizations() {
      return values(this.$store.state.user.organizations)
    },
    user() {
      return this.$store.state.user.user
    },
    fullName() {
      return `${get(this.user, 'firstName')} ${get(this.user, 'lastName')}`
    },
    userFirstChar() {
      return get(this.user.firstName, [0], 'X')
    },
    homepageLink() {
      return process.env.NUXT_ENV_BASE_URL
    },
    historyLink() {
      return `${process.env.NUXT_ENV_BASE_URL}/utilisateur/historique`
    },
    userProfileLink() {
      return `${process.env.NUXT_ENV_BASE_URL}/utilisateur/profil`
    },
    inscriptionLink() {
      return `${process.env.NUXT_ENV_BASE_URL}/inscription`
    }
  },
  methods: {
    async logout() {
      this.tracking.track('Logout', {
        userId: this.user.userId
      })
      this.tracking.reset()
      this.disconnecting = true
      await this.$store.dispatch('user/disconnect')
      this.disconnecting = false
    },
    getOrgName(org) {
      return get(org, 'name')
    },
    getOrgFirstChar(org) {
      return get(this.getOrgName(org), [0], 'X')
    },
    ux(tag) {
      return get(this, `$root.ux.mainLayout.header.${tag}`)
    }
  }
}
