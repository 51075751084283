import { useContext } from '@nuxtjs/composition-api'

export interface ConfigEnv {
  SEGMENT_KEY: string
  SEGMENT_ENABLED: boolean
  DD_RUM_APPLICATION_ID: string
  DD_RUM_CLIENT_TOKEN: string
  DD_SITE: string
  DD_SERVICE: string
  DD_ENV: string
  DD_RUM_SESSION_SAMPLE_RATE: string
  DD_RUM_SESSION_REPLAY_SAMPLE_RATE: string
  FEATURE_HIDE_WELCOME_MODAL: boolean
  FEATURE_MOBILE_PROMOTION: boolean
  FEATURE_CAMPAIGN_EXPORT: string
  FEATURE_RF_SEND_CONFIGURATION: boolean
  FEATURE_DELEGATION_LETTER: boolean
  FEATURE_NEW_TIERS: string
  FEATURE_PAYMENT_PARAMETERS: string
  NUXT_ENV_OLD_BO_URL: string
  NUXT_ENV_BASE_URL: string
  NUXT_ENV_BO_URL: string
  NUXT_ENV_BASE_API_BO: string
  NUXT_ENV_AUTH_URL_BO: string
  NUXT_ENV_AUTH_FRONT: string
}

export default (): ConfigEnv => {
  const { $config } = useContext()

  const {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_RUM_APPLICATION_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    FEATURE_HIDE_WELCOME_MODAL,
    FEATURE_MOBILE_PROMOTION,
    FEATURE_CAMPAIGN_EXPORT,
    FEATURE_RF_SEND_CONFIGURATION,
    FEATURE_DELEGATION_LETTER,
    FEATURE_NEW_TIERS,
    FEATURE_PAYMENT_PARAMETERS,
    NUXT_ENV_OLD_BO_URL,
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BO_URL,
    NUXT_ENV_BASE_API_BO,
    NUXT_ENV_AUTH_URL_BO,
    NUXT_ENV_AUTH_FRONT
  } = $config

  return {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_RUM_APPLICATION_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    FEATURE_HIDE_WELCOME_MODAL,
    FEATURE_MOBILE_PROMOTION,
    FEATURE_CAMPAIGN_EXPORT,
    FEATURE_RF_SEND_CONFIGURATION,
    FEATURE_DELEGATION_LETTER,
    FEATURE_NEW_TIERS,
    FEATURE_PAYMENT_PARAMETERS,
    NUXT_ENV_OLD_BO_URL,
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BO_URL,
    NUXT_ENV_BASE_API_BO,
    NUXT_ENV_AUTH_URL_BO,
    NUXT_ENV_AUTH_FRONT
  }
}
