import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, FormType, StoreRouteParams } from '../helpers/tsenums'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

class State {
  entities: string[] = []
  status: Status = Status.SUCCESS
  fetchedFrom: { [key: string]: string | null } = {
    form: null,
    organization: null
  }
  organizationAdmins: string[] = []
}

export const state = () => new State()
export const getters = {
  hasLoadedAdmins:
    (state: State) =>
    (formSlug: string, orgSlug: string): boolean => {
      return state.fetchedFrom.form === formSlug && state.fetchedFrom.organization === orgSlug
    }
}

export const mutations = <MutationTree<State>>{
  SET_ADMINS(state, { admins, orgSlug }: { admins: string[]; orgSlug: string }) {
    state.entities = [...admins]
    state.fetchedFrom.organization = orgSlug
  },
  SET_ADMINS_STATUS(state, { status }: { status: Status }) {
    state.status = status
  },
  SET_ORGANIZATION_ADMINS(state, { admins }: { admins: string[] }) {
    state.organizationAdmins = admins
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchAdmins(
    { commit, getters },
    { routeParams, forceReload = false }: { routeParams: StoreRouteParams; forceReload: boolean }
  ): Promise<void> {
    const { orgSlug, formSlug, type } = routeParams

    if (!forceReload && getters.hasLoadedAdmins(formSlug, orgSlug)) return Promise.resolve()

    commit('SET_ADMINS_STATUS', { status: Status.LOADING })

    try {
      const admins = await this.$api.$get(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/admins`
      )
      commit('SET_ADMINS', { admins, orgSlug })
      commit('SET_ADMINS_STATUS', { status: Status.SUCCESS })
    } catch (error) {
      commit('SET_ADMINS_STATUS', { status: Status.ERROR })
      throw error
    }
  },

  fetchOrganizationAdmins({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api
      .$get(`/organizations/${orgSlug}/admins`)
      .then(admins => commit('SET_ORGANIZATION_ADMINS', { admins, orgSlug }))
  },
  addOrganizationAdmin({ commit }, { orgSlug, email }: { orgSlug: string; email: string }) {
    return this.$api
      .$post(`/organizations/${orgSlug}/admins`, { adminEmails: [email] })
      .then(admins => {
        commit('SET_ORGANIZATION_ADMINS', { admins, orgSlug })
      })
  },
  deleteOrganizationAdmin({ commit }, { orgSlug, email }: { orgSlug: string; email: string }) {
    return this.$api
      .$delete(`/organizations/${orgSlug}/admins`, { data: { adminEmails: [email] } })
      .then(admins => {
        commit('SET_ORGANIZATION_ADMINS', { admins, orgSlug })
      })
  },
  createAdmin(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      email
    }: { orgSlug: string; formSlug: string; type: FormType; email: string }
  ) {
    commit('SET_ADMINS_STATUS', { status: Status.LOADING })

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/admins`, { adminEmails: [email] })
      .then(data => {
        commit('SET_ADMINS', { admins: data, orgSlug })
        commit('SET_ADMINS_STATUS', { status: Status.SUCCESS })
      })
      .catch(error => {
        commit('SET_ADMINS_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  deleteAdmin(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      email
    }: { orgSlug: string; formSlug: string; type: FormType; email: string }
  ) {
    commit('SET_ADMINS_STATUS', { status: Status.LOADING })

    return this.$api
      .$delete(`/organizations/${orgSlug}/forms/${type}/${formSlug}/admins`, {
        data: { adminEmails: [email] }
      })
      .then(data => {
        commit('SET_ADMINS', { admins: data, orgSlug })
        commit('SET_ADMINS_STATUS', { status: Status.SUCCESS })
      })
      .catch(error => {
        commit('SET_ADMINS_STATUS', { status: Status.ERROR })
        throw error
      })
  }
}
