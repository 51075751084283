import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, FormType, CustomField } from '../helpers/tsenums'
import getParticipantsQueryUrl from '@/components/participants/list/helpers/getParticipantsQueryUrl'
import getExportRequestConfig from '@/components/participants/list/helpers/getExportRequestConfig'
import getExportFileName from '@/components/participants/list/helpers/getExportFileName'
import {
  ParticipantsFilters,
  ExportFormat
} from '@/components/participants/list/types/participants'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface Attendee {
  order: Order
  payer: Payer
  payments?: Payment[]
  name: string
  user: User
  priceCategory: string
  ticketUrl: string
  tierDescription: string
  id: number
  amount: number
  type: string
  initialAmount: number
  state: string
}

interface Order {
  id: number
  date: Date
  formSlug: string
  formType: string
  organizationName: string
  organizationSlug: string
  formName: string
  meta: Meta
  isAmountHidden: boolean
}

interface Meta {
  createdAt: Date
  updatedAt: Date
}

interface Payer {
  email: string
  country?: string
  firstName?: string
  lastName?: string
}

interface Payment {
  cashOutState?: string
  shareAmount: number
  id: number
  amount: number
  date: Date
  paymentMeans: string
  state: string
  meta: Meta
}

interface User {
  firstName: string
  lastName: string
}

interface OfflineOrder {
  paymentMeans: string
  amount?: number
  date: Date
  email: string
  lastName: string
  firstName: string
  address: string
  city: string
  zipCode: string
  country: string
  companyName?: string
  tierType: string
  tierId: number | string
  extraOptionId: number | string
  comment: string
  customFields: CustomField[]
}

interface Search {
  query: null
  startDate: null
  endDate: null
  tierIds: string[]
  itemIds?: string[]
}

class State {
  entities: Attendee[] = []
  status: Status = Status.SUCCESS
  continuationToken: string = ''
  remaining: number = 0
  total: number = 0
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  ADD_ATTENDEES(state, attendees: Attendee[]) {
    state.entities = [...state.entities, ...attendees]
  },
  SET_ATTENDEES(state, attendees: Attendee[]) {
    state.entities = attendees
  },
  SET_ATTENDEES_REMAINING(state, remaining: number) {
    state.remaining = remaining
  },
  SET_ATTENDEES_CONTINUATION_TOKEN(state, token: string) {
    state.continuationToken = token
  },
  SET_TOTAL_ATTENDEES(state, total: number) {
    state.total = total
  }
}

export const actions = <ActionTree<State, any>>{
  async getParticipants(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      filters,
      isFlipping
    }: {
      orgSlug: string
      formSlug: string
      type: FormType
      filters: ParticipantsFilters
      isFlipping: boolean
    }
  ) {
    const endPoint = isFlipping ? 'participants2' : 'participants'
    const url = getParticipantsQueryUrl(
      `organizations/${orgSlug}/forms/${type}/${formSlug}/${endPoint}`,
      { ...filters, pageSize: 20 }
    )
    const { pagination, data } = await this.$api.$get(url)
    commit('SET_ATTENDEES_CONTINUATION_TOKEN', pagination.continuationToken)
    commit('SET_ATTENDEES_REMAINING', pagination.totalCount - data.length)

    return { pagination, data }
  },
  async fetchParticipants(
    { commit, dispatch },
    {
      orgSlug,
      formSlug,
      type,
      filters,
      isFlipping
    }: {
      orgSlug: string
      formSlug: string
      type: FormType
      filters: ParticipantsFilters
      isFlipping: boolean
    }
  ) {
    commit('SET_ATTENDEES', [])
    const { pagination, data } = await dispatch('getParticipants', {
      orgSlug,
      formSlug,
      type,
      filters,
      isFlipping
    })
    commit('SET_ATTENDEES', data)
    commit('SET_TOTAL_ATTENDEES', pagination.totalCount)
  },
  async fetchNextParticipants(
    { commit, dispatch, state },
    {
      orgSlug,
      formSlug,
      type,
      filters,
      isFlipping
    }: {
      orgSlug: string
      formSlug: string
      type: FormType
      filters: ParticipantsFilters
      isFlipping: boolean
    }
  ) {
    const { data } = await dispatch('getParticipants', {
      orgSlug,
      formSlug,
      type,
      filters: { ...filters, continuationToken: state.continuationToken },
      isFlipping
    })
    commit('ADD_ATTENDEES', data)
  },
  async fetchOldExportParticipants(
    _,
    {
      orgSlug,
      formSlug,
      type,
      filters,
      format
    }: {
      orgSlug: string
      formSlug: string
      type: FormType
      filters: ParticipantsFilters
      format: ExportFormat
    }
  ) {
    const url = getParticipantsQueryUrl(
      `organizations/${orgSlug}/forms/${type}/${formSlug}/participants`,
      filters
    )
    const requestConfig = getExportRequestConfig(format)
    const { data, headers } = await this.$api.get(url, requestConfig)
    const filename = getExportFileName(headers)

    return { filename, blob: data }
  },
  async fetchNewExportParticipants(
    _,
    {
      orgSlug,
      formSlug,
      type,
      filters,
      format
    }: {
      orgSlug: string
      formSlug: string
      type: FormType
      filters: ParticipantsFilters
      format: ExportFormat
    }
  ) {
    const url = getParticipantsQueryUrl(
      `organizations/${orgSlug}/forms/${type}/${formSlug}/participants2`,
      filters
    )
    const requestConfig = getExportRequestConfig(format)
    const { data, headers } = await this.$api.get(url, requestConfig)
    const filename = getExportFileName(headers)

    return { filename, blob: data }
  },
  addOfflineOrder(
    _,
    {
      orgSlug,
      formSlug,
      type,
      data
    }: { orgSlug: string; formSlug: string; type: FormType; data: OfflineOrder }
  ) {
    return this.$api.$post(
      `/organizations/${orgSlug}/forms/${type}/${formSlug}/offline-orders`,
      data
    )
  },
  editOfflineOrder(
    _,
    {
      orgSlug,
      formSlug,
      type,
      id,
      data
    }: { orgSlug: string; formSlug: string; type: FormType; id: number; data: OfflineOrder }
  ) {
    return this.$api.$put(
      `/organizations/${orgSlug}/forms/${type}/${formSlug}/offline-orders/${id}`,
      data
    )
  },
  deleteOfflineOrder(
    _,
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    return this.$api.$delete(
      `/organizations/${orgSlug}/forms/${type}/${formSlug}/offline-orders/${id}`
    )
  },
  addFileOfflineOrder(
    _,
    {
      orgSlug,
      formSlug,
      type,
      file
    }: { orgSlug: string; formSlug: string; type: FormType; file: File }
  ) {
    const formData = new FormData()
    formData.append('file', file)
    return this.$api.$post(
      `/organizations/${orgSlug}/forms/${type}/${formSlug}/offline-orders/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
}
