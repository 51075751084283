
import { enum as enums } from '@ha/helpers'
import { ApiTypesToUrl } from '@/helpers/enums'
import useFeatureFlipping from '@/composables/useFeatureFlipping'
import ButtonLink from '@/components/globals/buttons/ButtonLink.vue'
import VerificationAuthorizedAmount from '@/components/verification/monopage/VerificationAuthorizedAmount.vue'

export default {
  name: 'SidebarLinksOrganization',
  components: { ButtonLink, VerificationAuthorizedAmount },
  props: {
    isLoading: Boolean
  },
  setup() {
    const { getFlippingRFSendConfiguration } = useFeatureFlipping()

    return {
      showFiscalReceiptSendingPreferencesMenu: [2, 3].includes(getFlippingRFSendConfiguration())
    }
  },
  data() {
    return {
      showAccountMenu: false
    }
  },
  computed: {
    isOrganizationAdmin() {
      return ['OrganizationAdmin'].includes(
        this.$store.state.user.organizations[this.$route.params.organization]?.role
      )
    },
    organization() {
      return this.$store.state.organizations.enriched[this.organizationSlug] || {}
    },
    organizationSlug() {
      return this.$route.params.organization
    },
    paymentsTracking() {
      return {
        text: this.tr('paymentsTracking'),
        icon: ['fas', 'chart-bar'],
        to: `/${this.organizationSlug}/paiements`
      }
    },
    hasDonations() {
      return this.availableFormtypes.includes(enums.FormType.DONATION)
    },
    donations() {
      let links = {
        href: `${process.env.NUXT_ENV_BASE_URL}/associations/${this.organizationSlug}/administration/creation-formulaire`
      }

      if (this.hasDonations) {
        links = {
          isActive: this.$route.path === `/${this.organizationSlug}/${ApiTypesToUrl.DONATION}`,
          to: `/${this.organizationSlug}/${ApiTypesToUrl.DONATION}`
        }
      }
      return {
        ...links,
        text: this.tr('myDonations'),
        icon: ['fas', 'heart']
      }
    },
    hasCrowdfundings() {
      return this.availableFormtypes.includes(enums.FormType.CROWDFUNDING)
    },
    crowdfundings() {
      let links = {
        href: `${process.env.NUXT_ENV_BASE_URL}/associations/${this.organizationSlug}/administration/creation-campagne`
      }

      if (this.hasCrowdfundings) {
        links = {
          isActive: this.$route.path === `/${this.organizationSlug}/collectes`,
          to: `/${this.organizationSlug}/collectes`
        }
      }

      return {
        ...links,
        text: this.tr('myCrowdfundings'),
        icon: ['fas', 'flag']
      }
    },
    hasShops() {
      return this.availableFormtypes.includes(enums.FormType.SHOP)
    },
    shops() {
      return {
        isActive: this.$route.path === `/${this.organizationSlug}/boutiques`,
        text: this.tr('myShops'),
        to: this.hasShops
          ? `/${this.organizationSlug}/boutiques`
          : `/${this.organizationSlug}/boutiques/creation`,
        icon: ['fas', 'shopping-cart']
      }
    },
    hasMemberships() {
      return this.availableFormtypes.includes(enums.FormType.MEMBERSHIP)
    },
    memberships() {
      return {
        isActive: this.$route.path === `/${this.organizationSlug}/adhesions`,
        to: this.hasMemberships
          ? `/${this.organizationSlug}/adhesions`
          : `/${this.organizationSlug}/adhesions/creation`,
        text: this.tr('myMemberships'),
        icon: ['fas', 'id-card']
      }
    },
    hasEvents() {
      return this.availableFormtypes.includes(enums.FormType.EVENT)
    },
    events() {
      return {
        isActive: this.$route.path === `/${this.organizationSlug}/evenements`,
        to: this.hasEvents
          ? `/${this.organizationSlug}/evenements`
          : `/${this.organizationSlug}/evenements/creation`,
        text: this.tr('myEvents'),
        icon: ['fas', 'ticket-alt']
      }
    },
    hasPaymentForms() {
      return this.availableFormtypes.includes(enums.FormType.PAYMENTFORM)
    },
    availableFormtypes() {
      return this.$store.state.organizations.formTypes[this.organizationSlug] || []
    },
    paymentForms() {
      return {
        text: this.tr('myPaymentForms'),
        icon: ['fas', 'tag'],
        isActive: this.$route.path === `/${this.organizationSlug}/ventes`,
        to: this.hasPaymentForms
          ? `/${this.organizationSlug}/ventes`
          : `/${this.organizationSlug}/ventes/creation`
      }
    },
    orgPage() {
      return {
        text: this.tr('orgPage'),
        href: `${process.env.NUXT_ENV_BASE_URL}/associations/${this.organizationSlug}/administration/ma-page-publique`
      }
    },
    payments() {
      return {
        text: this.tr('payments'),
        to: `/${this.organizationSlug}/mon-compte/versements`
      }
    },
    settings() {
      return {
        text: this.tr('settings'),
        href: `${process.env.NUXT_ENV_BASE_URL}/associations/${this.organizationSlug}/administration/parametres`
      }
    },
    compliance() {
      return {
        text: this.tr('compliance'),
        to: `/${this.organizationSlug}/authentification`,
        icon: ['fas', 'certificate']
      }
    },
    access() {
      return {
        text: this.tr('accessRights'),
        href: `${process.env.NUXT_ENV_BASE_URL}/associations/${this.organizationSlug}/administration/droits`
      }
    },
    api() {
      return {
        text: this.tr('api'),
        to: `/${this.organizationSlug}/integrations`
      }
    },
    fiscalReceipt() {
      return {
        text: this.tr('fiscalReceipt'),
        href: `${process.env.NUXT_ENV_BASE_URL}/associations/${this.organizationSlug}/administration/configuration-recus-fiscaux`
      }
    },
    FiscalReceiptSendingPreferences() {
      return {
        text: this.tr('fiscalReceiptSendingPreferences'),
        href: `/${this.organizationSlug}/recu-fiscal/reduction-impot`
      }
    }
  },
  methods: {
    toggleAccountMenu() {
      this.showAccountMenu = !this.showAccountMenu
    },
    close() {
      this.$emit('close')
    },
    tr(name, ...other) {
      return this.$t(`layouts.sidebar.${name}`, ...other)
    }
  }
}
