import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

declare global {
  interface Window {
    appCues: any
    dataLayer: any
    wootricSettings: any
  }
}

interface LegalStructures {
  [index: number]: string
}

interface TaxInformationTexts {
  [index: number]: TaxInformationText
}

interface TaxInformationText {
  isColucheLawEnable: boolean
  isIfiEnable: boolean
  individualText: string
  companyText: string
}

interface OrganizationLegalInformation {
  legalStructure: string | null
  agreement: string | null
  isFiscalReceiptEligible: boolean | null
  isColucheLawEnable: boolean
  isIfiEnable: boolean
}

interface SendingSettings {
  organizationType: string | null
  organizationActivityField: string | null
  organizationObject: string | null
  organizationHeadOfficeAddress: string | null
  organizationHeadOfficeZipCode: string | null
  organizationHeadOfficeCity: string | null
  signingMemberFirstName: string | null
  signingMemberLastName: string | null
  signingMemberFunction: string | null
  customizeFiscalReceiptsNumbering: boolean
  signingMemberSignatureUrl: string | null
}

class State {
  legalStructures: LegalStructures = []
  taxInformationTexts: TaxInformationTexts = []
  organizationLegalInformation: OrganizationLegalInformation | null = null
  sendingSettings: SendingSettings | null = null
}

export const state = () => new State()

export const getters = {
  hasLoadedOrganizationsLegalStructures: state => () => state.legalStructures.length !== 0,
  hasLoadedTaxInformationTexts: state => () => state.taxInformationTexts.length !== 0
}

export const mutations = <MutationTree<State>>{
  SET_ORGANIZATIONS_LEGAL_STRUCTURES(state, { structures }: { structures: LegalStructures }) {
    state.legalStructures = structures
  },
  SET_TAX_INFORMATION_TEXTS(state, { texts }: { texts: TaxInformationTexts }) {
    state.taxInformationTexts = texts
  },
  SET_ORGANIZATION_LEGAL_INFORMATION(
    state,
    organizationLegalInformation: OrganizationLegalInformation
  ) {
    state.organizationLegalInformation = organizationLegalInformation
  },
  SET_SENDING_SETTINGS(state, sendingSettings: SendingSettings) {
    state.sendingSettings = sendingSettings
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchOrganizationsLegalStructures({ commit, getters }) {
    if (getters.hasLoadedOrganizationsLegalStructures()) {
      return Promise.resolve()
    }

    try {
      const structures = await this.$api.$get('/organizations/legal-informations/legal-structures')
      commit('SET_ORGANIZATIONS_LEGAL_STRUCTURES', { structures })
    } catch (error) {
      throw error
    }
  },

  async fetchTaxInformationTexts(
    { commit, getters },
    { organizationSlug }: { organizationSlug: string }
  ) {
    if (getters.hasLoadedTaxInformationTexts()) {
      return Promise.resolve()
    }

    const texts = await this.$api.$get('/organizations/legal-informations/tax-information-texts', {
      params: { organizationSlug }
    })
    commit('SET_TAX_INFORMATION_TEXTS', { texts })
  },

  saveOrganizationLegalInformationForm({ commit, getters }, form: OrganizationLegalInformation) {
    commit('SET_ORGANIZATION_LEGAL_INFORMATION', { ...form })
  },

  saveSendingSettingsForm({ commit, getters }, form: SendingSettings) {
    commit('SET_SENDING_SETTINGS', { ...form })
  },

  resetOrganizationLegalInformation(
    { dispatch },
    { organizationSlug }: { organizationSlug: string }
  ) {
    dispatch('loadOrganizationLegalInformation', { organizationSlug })
  },

  async loadOrganizationLegalInformation(
    { commit },
    { organizationSlug }: { organizationSlug: string }
  ) {
    const organizationInfo = await this.$api.$get(
      `/organizations/legal-informations/${organizationSlug}/configuration`,
      {
        params: { organizationSlug }
      }
    )
    const legalStructures = await this.$api.$get(
      '/organizations/legal-informations/legal-structures'
    )

    const organizationLegalStructure = legalStructures.find(
      ({ id }) => id === organizationInfo.legalStructureId
    )

    commit('SET_ORGANIZATION_LEGAL_INFORMATION', {
      legalStructure: organizationLegalStructure.legalStructure,
      agreement: organizationLegalStructure.agreement,
      isFiscalReceiptEligible: organizationLegalStructure.isFiscalReceiptEligible,
      isColucheLawEnable: organizationInfo.isColucheLawEnable,
      isIfiEnable: organizationInfo.isIfiEnable
    })
  },

  async loadSendingSettings({ commit }, { organizationSlug }: { organizationSlug: string }) {
    // TODO: Fetch data from `GET {organizationSlug}/tax-receipt/configuration`
    commit('SET_SENDING_SETTINGS', {
      organizationType: '',
      organizationActivityField: '',
      organizationObject:
        "Nous apportons notre aide aux plus démunis, et oeuvrons pour la protection des enfants dans les moments difficiles en leur permettant de continuer d'être scolarisés.",
      organizationHeadOfficeAddress: '13 rue de la rue',
      organizationHeadOfficeZipCode: '33000',
      organizationHeadOfficeCity: 'Bordeaux'.toUpperCase(),
      signingMemberFirstName: '',
      signingMemberLastName: '',
      signingMemberFunction: '',
      customizeFiscalReceiptsNumbering: false,
      signingMemberSignatureUrl: ''
    })
  },

  resetSendingSettings({ dispatch }, { organizationSlug }: { organizationSlug: string }) {
    dispatch('loadSendingSettings', { organizationSlug })
  }
}
