
import { values, get } from 'lodash-es'
import { Role } from '@/helpers/enums'
import ButtonLink from '@/components/globals/buttons/ButtonLink.vue'
import useTracking from '@/composables/useTracking'

export default {
  name: 'SidebarFooter',
  components: {
    ButtonLink
  },
  setup() {
    const { tracking } = useTracking()

    return { tracking }
  },
  data() {
    return {
      disconnecting: false,
      showOrgMenu: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    organizationsList() {
      return values(this.$store.state.user.organizations).filter(
        (org) => get(org, 'role') === Role.ORGANIZATION_ADMIN
      )
    },
    organizations() {
      return values(this.$store.state.organizations.enriched)
    },
    userProfilLink() {
      return `${process.env.NUXT_ENV_BASE_URL}/utilisateur/profil`
    }
  },
  methods: {
    async logout() {
      this.tracking.track('Logout', {
        userId: this.user.userId
      })
      this.tracking.reset()
      this.disconnecting = true
      await this.$store.dispatch('user/disconnect')
      this.disconnecting = false
    },
    resizedSrc(src, size) {
      const separator = src && src.includes('?') ? '&' : '?'
      const resize = `resize=fill:${size.width}:${size.height}`

      return src + separator + resize
    },
    toggleOrgMenu() {
      this.showOrgMenu = !this.showOrgMenu
    },
    getOrgPicture(org) {
      return get(org, 'logo')
    },
    getOrgName(org) {
      return get(org, 'name')
    },
    getOrgFirstChar(org) {
      return get(this.getOrgName(org), [0], 'X')
    }
  }
}
