import https from 'https'
import { last, toString } from 'lodash-es'
import { accessTokenCookieName, refreshTokenCookieName } from '@/constants'
import getUnauthorizedRedirection from '@/helpers/redirection/getUnauthorizedRedirection'
import { functions } from '@ha/helpers'

const redirectAfterUnauthorized = ($cookies, store, configEnv) => {
  const accessToken = $cookies.get(accessTokenCookieName)
  const redirectUrl = getUnauthorizedRedirection(
    configEnv,
    accessToken,
    store.getters['user/organizations']
  )
  window.location.replace(redirectUrl)
}

const createAuthClient = ($axios, configEnv) => {
  const auth = $axios.create({
    baseURL: configEnv.NUXT_ENV_AUTH_URL_BO,
    withCredentials: true
  })

  return auth
}

const createApiClient = (agent, $axios, $cookies, store, configEnv) => {
  const api = $axios.create({
    withCredentials: true,
    httpsAgent: agent,
    baseURL: configEnv.NUXT_ENV_BASE_API_BO
  })
  api.onRequest((config) => {
    const accessToken = $cookies.get(accessTokenCookieName)

    if (accessToken) {
      config.headers.common.Authorization = `Bearer ${accessToken}` // eslint-disable-line no-param-reassign
    }
  })

  api.onResponseError(async (error) => {
    const config = error.config
    const endpoint = last(toString(config.url).split('/'))
    const refreshToken = $cookies.get(refreshTokenCookieName)

    if (error.response?.status === 401 && !config.retry) {
      console.info('Failed to fetch data: refreshing token...')

      if (refreshToken) {
        config.retry = true

        return store
          .dispatch('user/refreshToken')
          .then(() => {
            const accessToken = $cookies.get(accessTokenCookieName)
            config.headers['Authorization'] = `Bearer ${accessToken}`
            console.warn('[dispatch] fetching', endpoint, 'again')
            return api.request(config)
          })
          .catch((errorRefresh) => {
            /* Add console.error to track error in datadog */
            console.error(errorRefresh)
            redirectAfterUnauthorized($cookies, store, configEnv)
          })
      } else {
        /* Add console.error to track error in datadog */
        console.error(error)
        redirectAfterUnauthorized($cookies, store, configEnv)
      }
    }

    const isRequestPreventedFromRedirection = error.config.url === '/users/me/password/verify'

    if (!isRequestPreventedFromRedirection && error.response?.status === 403) {
      /* Add console.error to track error in datadog */
      console.error(error)

      /* Prevent infinite error loop because of refetch user (l.89) */
      if (error.config.url === '/agg/user') {
        const redirectUrl = getUnauthorizedRedirection(configEnv)
        window.location.replace(redirectUrl)
        return
      }

      /* If the user id has changed then we need to refetch user's information
      to redirect correctly to the organisation for which the user is administrator.  */
      const accessToken = $cookies.get(accessTokenCookieName)
      const { userId } = functions.getAccessTokenInformations(accessToken)
      if (userId && userId !== store.getters['user/user']?.userId) {
        await store.dispatch('user/fetchUser', true)
      }

      redirectAfterUnauthorized($cookies, store, configEnv)
    }

    return Promise.reject(error)
  })

  return api
}

export default ({ $axios, app, store, env }, inject) => {
  const { $cookies } = app

  // Allows unauthorized certificates in development for debugging purposes
  // -> If used locally, the API has a self signed certificate
  const agent = new https.Agent({
    rejectUnauthorized: process.env.NODE_ENV !== 'development'
  })

  const apiAuth = createAuthClient($axios, env)
  const apiClient = createApiClient(agent, $axios, $cookies, store, env)

  inject('auth', apiAuth)
  inject('api', apiClient)
}
