import useConfigEnv from '@/composables/useConfigEnv'
import useRouteParams from '@/composables/useRouteParams'

export default () => {
  const {
    FEATURE_CAMPAIGN_EXPORT,
    FEATURE_RF_SEND_CONFIGURATION,
    FEATURE_DELEGATION_LETTER,
    FEATURE_NEW_TIERS,
    FEATURE_PAYMENT_PARAMETERS,
  } = useConfigEnv()
  const { formType } = useRouteParams()

  const getAllowedFormTypes = (configEnv) => configEnv.split(',') || []

  const getFlippingCampaignExport = (localForm) => {
    const currentFormType = localForm?.formType || formType.value
    const allowedFormTypes = getAllowedFormTypes(FEATURE_CAMPAIGN_EXPORT)
    return allowedFormTypes.includes(currentFormType)
  }

  const getFlippingRFSendConfiguration = () => FEATURE_RF_SEND_CONFIGURATION
  const getFlippingDelegationLetter = () => !!FEATURE_DELEGATION_LETTER

  const getFlippingNewTiers = (localFormType) => {
    if (!FEATURE_NEW_TIERS) return false
    return getAllowedFormTypes(FEATURE_NEW_TIERS).includes(localFormType)
  }

  const getFlippingPaymentParameters = (localFormType) => {
    if (!FEATURE_PAYMENT_PARAMETERS) return false
    return getAllowedFormTypes(FEATURE_PAYMENT_PARAMETERS).includes(localFormType)
  }

  return {
    getFlippingCampaignExport,
    getFlippingRFSendConfiguration,
    getFlippingNewTiers,
    getFlippingPaymentParameters,
    getFlippingDelegationLetter
  }
}
