import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, FormType } from '../helpers/tsenums'
import getStoreFormKey from '../helpers/getStoreFormKey'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface Tier {
  id: number
  label: string
  description: string
  entriesTaken: number
  maxEntries?: number
  price: number
  priceCategory: string
}

interface Option {
  id: number
  label: string
  description: string
  entriesTaken: number
  maxEntries: number
  priceCategory: string
}

class State {
  status: Status = Status.SUCCESS
  totalParticipant: number = 0
  ungroupedTiers: Tier[] = []
  additionalOptions: Option[] = []
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  SET_STATS_TOTAL_PARTICIPANTS(state, totalParticipant: number) {
    state.totalParticipant = totalParticipant
  },
  SET_STATS_UNGROUPED_TIERS(state, tiers: Tier[]) {
    state.ungroupedTiers = tiers
  },
  SET_STATS_ADDITIONAL_OPTIONS(state, options: Option[]) {
    state.additionalOptions = options
  },
  SET_STATS_STATUS(state, status: Status) {
    state.status = status
  }
}

export const actions = <ActionTree<State, any>>{
  fetchStats(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    commit('SET_STATS_STATUS', Status.LOADING)
    return this.$api
      .$get(`/organizations/${orgSlug}/forms/${type}/${formSlug}/stats`)
      .then(data => {
        const key = getStoreFormKey({ orgSlug, formSlug, type })
        commit('SET_STATS_TOTAL_PARTICIPANTS', data.totalParticipant)
        commit('SET_STATS_UNGROUPED_TIERS', data.unGroupedTiers)
        commit('SET_STATS_ADDITIONAL_OPTIONS', data.additionalOptions)
        commit('SET_STATS_STATUS', Status.SUCCESS)
        commit(
          'forms/SET_FORM_PARTICIPANT_COUNT',
          { key, participantCount: data.totalParticipant },
          { root: true }
        )
        return data
      })
      .catch(reason => {
        commit('SET_STATS_STATUS', Status.ERROR)
        throw reason
      })
  }
}
